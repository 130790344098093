import React from 'react'
import Heading from 'uiKit/texts/Heading'
import Paragraph from 'uiKit/texts/Paragraph'
import Switch from 'uiKit/Switch'

import { toggleBotEnabler } from '../../../api/settings'

import * as S from './BotEnablerBlock.styles'

export const BotEnablerBlock = ({ activeBot }) => {

  const handleToggleBotEnabler = e => {
    toggleBotEnabler(activeBot.id, e.target.checked)
  }

  return (
    <S.BotEnablerBlock>
      <Heading>Enable/Disable bot</Heading>
      <Paragraph>
        Once disabled, the bot will not be shown on the page.
      </Paragraph>
      <Switch
        useSecondaryColor={true}
        label="Enable bot"
        checked={activeBot?.botEnabled}
        onChange={handleToggleBotEnabler}
      />
    </S.BotEnablerBlock>
  )
}
